import React, { useContext, useEffect } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import {
	PromotionBar,
	ResourceBar,
	Navbar,
	StandaloneResourceLink,
	MobileNav,
	NavLink,
	MobileNavLink,
	StandaloneNavLink,
	MobileStandaloneNavLink,
	ResourceLink,
} from 'ww-nav-components';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, makeStyles, Grid } from '@material-ui/core';

import PromotionContext from '../../context/PromotionContext';
import FormModalContext from '../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		background: theme.white,
		color: theme.workwaveBlue,
		padding: '0 0',
		borderTop: `1.3px ${theme.lightGray} solid`,
		minHeight: '64px',
		display: 'flex',
		justifyContent: 'center',
	},
	cta: {
		fontSize: '20px',
		marginLeft: '10px',
		[theme.breakpoints.down('lg')]: {
			padding: '12px 20px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '16px',
		},
	},
	img: {
		height: '3rem',
		marginTop: '.5rem',
		[theme.breakpoints.down('xs')]: {
			height: '2.5rem',
		},
	},
	resource: {
		display: 'block',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	mobile: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	desktop: {
		display: 'contents',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
}));

export const Nav = () => {
	const { nav } = useStaticQuery(graphql`
		{
			nav: sanityNav {
				title
				promotionBar {
					_rawContent
				}
				resourceBar {
					productLinks {
						title
						type
						mainName
						secondaryName
						category
						subcategory
						description
						slug {
							current
						}
						internalLink
					}
					productNavHeader
					productMobileFaIcon
					productCalloutFaIcon
					productCalloutTitle
					_rawProductCalloutContent
					mktplcLink
					salesMobileFaIcon
					salesNavHeader
					salesHeader
					resourceSalesLink {
						title
						type
						faIcon
						iconColor {
							hexValue
						}
						name
						description
						slug {
							current
						}
					}
					supportNavHeader
					supportMobileFaIcon
					supportHeader
					resourceSupportLink {
						title
						type
						faIcon
						iconColor {
							hexValue
						}
						name
						description
						slug {
							current
						}
					}
				}
				ppNavLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED, height: 60, width: 245)
					}
				}
				whyPPMobileFaIcon
				whyPPNavHeader
				whyPPLinks {
					title
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					slug {
						current
					}
					internalLink
				}
				whyPPCalloutCtas {
					image {
						asset {
							gatsbyImageData(placeholder: BLURRED, height: 240, width: 310)
						}
					}
					_rawContent
					link
					internalLink
				}
				featuresMobileFaIcon
				featuresNavHeader
				featuresLinks {
					title
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					slug {
						current
					}
					internalLink
				}
				featuresCalloutTitle
				featuresCalloutFaIcon
				_rawFeaturesCalloutContent
				featuresCalloutCtas {
					image {
						asset {
							gatsbyImageData(placeholder: BLURRED, height: 240, width: 310)
						}
					}
					_rawContent
					link
					internalLink
				}
				pricingMobileFaIcon
				pricingLink {
					title
					slug {
						current
					}
					internalLink
				}
				resourcesMobileFaIcon
				resourcesNavHeader
				resourcesLinks {
					title
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					slug {
						current
					}
					internalLink
				}
				resourcesCalloutTitle
				resourcesCalloutFaIcon
				_rawResourcesCalloutContent
				currentCustomersLink {
					title
					slug {
						current
					}
					internalLink
				}
				currentCustomersMobileFaIcon
				loginLink {
					title
					slug {
						current
					}
					internalLink
				}
				loginMobileFaIcon
				ctaText
			}
		}
	`);

	//only uncomment to make changes in nav content. nav hover makes it tough to make styling changes. This will freeze the nav open with devtools open after 5 seconds
	// useEffect(() => {
	// 	setTimeout(function () {
	// 		debugger;
	// 	}, 5000);
	// }, []);

	const classes = useStyles();
	const med = useMediaQuery('(max-width: 960px)');

	const { open, setOpen } = useContext(PromotionContext);
	const { handleModalClick } = useContext(FormModalContext);

	const handleSalesClick = (e) => {
		e.preventDefault();
		navigate('/contact-us');
	};

	const {
		promotionBar,
		resourceBar,
		whyPPLinks,
		whyPPNavHeader,
		whyPPCalloutCtas,
		whyPPMobileFaIcon,
		ctaText,
		ppNavLogo,
		featuresNavHeader,
		featuresMobileFaIcon,
		featuresLinks,
		featuresCalloutTitle,
		featuresCalloutFaIcon,
		_rawFeaturesCalloutContent,
		pricingLink,
		pricingMobileFaIcon,
		resourcesMobileFaIcon,
		resourcesNavHeader,
		resourcesLinks,
		resourcesCalloutTitle,
		resourcesCalloutFaIcon,
		_rawResourcesCalloutContent,
		currentCustomersLink,
		currentCustomersMobileFaIcon,
		loginLink,
		loginMobileFaIcon,
		featuresCalloutCtas,
	} = nav;

	return (
		<>
			{!!promotionBar ? (
				<PromotionBar
					promotion={promotionBar}
					open={open}
					setOpen={setOpen}
					barColor='#d10066'
					ucText
				/>
			) : null}

			<Grid item className={classes.resource}>
				<ResourceBar>
					<ResourceLink
						header={resourceBar.productNavHeader}
						links={resourceBar.productLinks}
						calloutIcon={resourceBar.productCalloutFaIcon}
						calloutTitle={resourceBar.productCalloutTitle}
						calloutContent={resourceBar._rawProductCalloutContent}
						resourceBar
					/>
					{/* <StandaloneResourceLink
						link='https://marketplace.workwave.com'
						title={resourceBar.mktplcLink}
						internal={false}
						// icon='cart-shopping'
					/> */}
					<ResourceLink
						header={resourceBar.salesNavHeader}
						contentHeader={resourceBar.salesHeader}
						links={resourceBar.resourceSalesLink}
						handleModalClick={handleSalesClick}
						internalLink
					/>
					<ResourceLink
						header={resourceBar.supportNavHeader}
						contentHeader={resourceBar.supportHeader}
						links={resourceBar.resourceSupportLink}
						handleModalClick={handleModalClick}
						internalLink={false}
					/>
				</ResourceBar>
			</Grid>

			<Navbar image={ppNavLogo.asset.gatsbyImageData}>
				{/* mobile nav responsive hide gets controlled in ww-nav-components */}
				<MobileNav ctaText={ctaText} handleModalClick={handleModalClick}>
					<MobileNavLink
						header={whyPPNavHeader}
						links={whyPPLinks}
						icon={whyPPMobileFaIcon}
					/>
					<MobileNavLink
						header={featuresNavHeader}
						links={featuresLinks}
						icon={featuresMobileFaIcon}
					/>
					<MobileStandaloneNavLink
						link={pricingLink.title}
						title={pricingLink.title}
						icon={pricingMobileFaIcon}
						internal
					/>
					<MobileNavLink
						header={resourcesNavHeader}
						links={resourcesLinks}
						icon={resourcesMobileFaIcon}
					/>
					<MobileNavLink
						header={resourceBar.productNavHeader}
						links={resourceBar.productLinks}
						icon={resourceBar.productMobileFaIcon}
					/>
					<MobileNavLink
						header={resourceBar.salesNavHeader}
						links={resourceBar.resourceSalesLink}
						icon={resourceBar.salesMobileFaIcon}
					/>
					<MobileNavLink
						header={resourceBar.supportNavHeader}
						links={resourceBar.resourceSupportLink}
						icon={resourceBar.supportMobileFaIcon}
					/>
					<MobileStandaloneNavLink
						link={currentCustomersLink.slug.current}
						title={currentCustomersLink.title}
						icon={currentCustomersMobileFaIcon}
						internal={currentCustomersLink.internalLink}
					/>
					<MobileStandaloneNavLink
						link={loginLink.slug.current}
						title={loginLink.title}
						icon={loginMobileFaIcon}
						internal={loginLink.internalLink}
					/>

					<MobileStandaloneNavLink
						link='https://marketplace.workwave.com'
						title={nav.resourceBar.mktplcLink}
						icon='cart-shopping'
						internal={false}
					/>
				</MobileNav>
				<Grid item className={classes.desktop}>
					<NavLink
						header={whyPPNavHeader}
						links={whyPPLinks}
						calloutCtas={whyPPCalloutCtas}
						// calloutIcon={featuresCalloutFaIcon}
						// calloutTitle={featuresCalloutTitle}
						// calloutContent={_rawFeaturesCalloutContent}
					/>
					<NavLink
						header={featuresNavHeader}
						links={featuresLinks}
						calloutIcon={featuresCalloutFaIcon}
						calloutTitle={featuresCalloutTitle}
						calloutContent={_rawFeaturesCalloutContent}
						calloutCtas={featuresCalloutCtas}
					/>
					<StandaloneNavLink
						link={pricingLink.slug.current}
						title={pricingLink.title}
						internal={pricingLink.internalLink}
					/>
					<NavLink
						header={resourcesNavHeader}
						links={resourcesLinks}
						calloutIcon={resourcesCalloutFaIcon}
						calloutTitle={resourcesCalloutTitle}
						calloutContent={_rawResourcesCalloutContent}
					/>
					<StandaloneNavLink
						link={currentCustomersLink.slug.current}
						title={currentCustomersLink.title}
						internal={currentCustomersLink.internalLink}
					/>
					<StandaloneNavLink
						link={loginLink.slug.current}
						title={loginLink.title}
						internal={false}
					/>

					<Button
						variant='contained'
						size='medium'
						color='secondary'
						className={classes.cta}
						onClick={(e) => handleModalClick(e)}>
						{ctaText}
					</Button>
				</Grid>
			</Navbar>
		</>
	);
};
