import React from 'react';
// import { IntercomProvider, useIntercom } from 'react-use-intercom';

// import { Script } from 'gatsby';
import { Layout } from './src/components/Layout/Layout';
import { PromotionProvider } from './src/context/PromotionContext';
import { FormModalProvider } from './src/context/FormModalContext';
import { FormProvider } from './src/context/FormContext';
import { IndustrySearchProvider } from './src/context/IndustrySearchContext';
import { RoiProvider } from './src/context/RoiContext';

import { navigate } from 'gatsby';
import { FaqSearchProvider } from './src/context/FaqSearchContext';
import { FeatureThumbnailProvider } from './src/context/FeatureThumbnailContext';
import { PromotionModalProvider } from './src/context/PromotionModalContext';
import { PardotProvider } from './src/context/PardotContext';

const INTERCOM_APP_ID = 'vqczfn1v';

export function wrapPageElement({ element, props }) {
	return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
	return (
    <PardotProvider>
      <PromotionModalProvider>
        <FaqSearchProvider>
          <FeatureThumbnailProvider>
            <RoiProvider>
              <IndustrySearchProvider>
                <FormProvider>
                  <FormModalProvider>
                    <PromotionProvider>{element}</PromotionProvider>
                  </FormModalProvider>
                </FormProvider>
              </IndustrySearchProvider>
            </RoiProvider>
          </FeatureThumbnailProvider>
        </FaqSearchProvider>
      </PromotionModalProvider>
    </PardotProvider>
	);
}
